/* eslint-disable */
import { getByIdForUp } from '@/api/product/protoApi'
import { SHELVES } from '@/utils/constant'

/**
* @description: 校验原型是未下架
* @param: params {Object}
* @param: params.id {Number}
* @return: Boolean
*/
export async function validateProtoUnShelf(params) {
  let protoData = await awaitResolve(
    getByIdForUp(params)
  )
  
  // 上架就报错
  if (!protoData) return false
  return protoData.status !== SHELVES
}