<template>
  <section>
    <BaseProductPage
      ref="BaseProductPage"
      :cols="cols"
      :formField="formField"
      :resetUrl="url"
      :isEdit="true"
      :isSearch="isSearch"
      :selection="permission.importPrice"
      :spanMethod="spanMethod"
      :hasCheckAllData4="true"
      :rowClassName="rowClassName"
      :expand-table-option="historyOption"
      :isMultipleReferSize="false"
    >
      <template #tips>
        <el-alert style="margin: -20px 0 20px" title="已上架原型无法设置或编辑价格" type="warning" show-icon></el-alert>
        <span>
          <importPriceBtn
            v-if="permission.importPrice"
            @success="() => $refs.BaseProductPage.refreshPage"
          ></importPriceBtn>
          <loadingBtn v-if="permission.exportPrice" type="primary" class="text-small ml10" @click="exportPriceExcel"
            >导出产品价格</loadingBtn
          >
        </span>
      </template>
      <el-table-column align="center" label="操作" width="180px">
        <template slot-scope="{ row, $index }">
          <el-button
            :uiid="`zd-${$index}-setPrice`"
            type="text"
            size="mini"
            class="filter-item"
            @click="handleSettingPrice(row)"
            v-if="permission.editPrice && categoriesList.length !== 0 && row.status !== 1"
          >
            {{ isSetPrice(row) ? '编辑' : '设置' }}价格
          </el-button>
          <div class="flex-warpper" v-if="categoriesList.length === 0">
            <span>暂无档位</span>
            <el-button
              v-if="permission.editGears"
              type="text"
              size="mini"
              class="filter-item"
              @click="$router.push({ path: '/product/editGears' })"
            >
              设置档位
            </el-button>
          </div>
        </template>
      </el-table-column>
    </BaseProductPage>
    <BaseDialog
      ref="baseeDialog"
      uiid="zd-setPrice-dialog"
      class="dialog-footer-pb30"
      style="text-align: left"
      v-if="showEditPriceDialog"
      @closeHandle="handleClosed"
      :dialogVisible.sync="showEditPriceDialog"
      :append-to-body="false"
      :modal-append-to-body="false"
      width="840px"
      maxHeight="562px"
      :title="isSetPrice(product) ? '编辑价格' : '设置价格'"
    >
      <div class="base-dialog-wrapper" v-loading="loading">
        <div class="product-wrapper flex">
          <baseImage size="80" imgSize="80" :src="figure(product)" fit="contain">
            <baseImage slot="error" src="@/assets/images/default.png" size="60" imgSize="60" alt="" class="block" />
          </baseImage>
          <div class="flex-middle ml10">
            <div class="product-name">原型名称: {{ product.chineseName }}</div>
            <div class="ml50">原型分类: {{ product.productCategory && product.productCategory.name }}</div>
          </div>
        </div>
        <div class="price-wrapper">
          <label for="select" class="selectLabel">档位分类：</label>
          <el-select
            v-model="categories"
            uiid="zd-categories"
            filterable
            size="medium"
            style="width: 210px"
            placeholder="请选择档位分类"
            id="select"
            @change="getCategorys"
          >
            <el-option v-for="{ id, name } in categoriesList" :key="id" :value="id" :label="name"></el-option>
          </el-select>
          <el-form :inline="true" :model="ruleForm" size="medium" :rules="rules" ref="ruleForm" label-width="155px">
            <el-form-item style="position: relative" label="初始价格：" prop="init_price">
              <helpDialog class="guide" />
              <el-input style="width: 210px" placeholder="请输入初始价格" v-model="ruleForm.init_price" uiid="zd-initPrice"></el-input>
            </el-form-item>
            <el-form-item style="position: relative" label="尺码价格的公差(d)：" prop="size">
              <helpDialog class="guide" />
              <el-input style="width: 210px" placeholder="请输入尺码价格的公差（d）" v-model="ruleForm.size" uiid="zd-sizeDiff"></el-input>
            </el-form-item>

            <div class="init-price">
              <el-form-item class="size-diff" label="档位价格的公差(d)：" prop="gear" style="position: relative">
                <helpDialog class="guide" />
                <el-input
                  uiid="zd-priceDiff"
                  style="width: 210px"
                  placeholder="请输入档位价格的公差（d）"
                  v-model="ruleForm.gear"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button uiid="zd-createPrice" type="primary" class="generate-button" @click="handleButtonClicked"> 生成价格 </el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="setting-price-wrapper">
          <div style="margin-top: 30px">
            生成的价格 <span class="text-danger">(档位区间的价格*每一级会员等级的折扣不能低于最低售价)</span>：
          </div>
          <setPriceTable
            class="setPriceStyle"
            ref="setPriceTable"
            uiid="zd-setPriceTable"
            v-if="geaData.length !== 0"
            @takeRef="takeRefs"
            :priceData="priceData"
            :priceColData="priceColData"
            :isSet="false"
            :isInputVisible="true"
          ></setPriceTable>
          <div v-else class="gears-wrapper">
            <router-link to="/product/editGears"> 前往设置档位 </router-link>
          </div>
        </div>
      </div>
      <template #footer>
        <LoadingBtn uiid="zd-setPrice-cancel" class="text-small" @click="handleCanceled">取消</LoadingBtn>
        <loadingBtn uiid="zd-setPrice-confirm" class="text-small" type="primary" @click="handleConfirmed">确定</loadingBtn>
      </template>
    </BaseDialog>
  </section>
</template>

<script>
import BaseProductPage from '../../baseProductPage'
import { priceField as formField } from '../../field'
import { priceManageCols as cols } from '../../cols'
import setPriceTable from '../../module/setPrice'
import { isMoreZero, validNumber, numMaxConstruct } from '@/utils/validate'
import helpDialog from '../priceManage/module/helpDialog'
import importPriceBtn from '@/views/product/maintain/module/importPrice'
import { getGearsCategoryList, getCurrentGears, updatePrice, getPrice } from '@/api/product/productApi'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import { exportWrap, getValueFromObj } from '@/utils'
import { validateProtoUnShelf } from '@/utils/utils/validate'
import { handlePriceData } from '@/views/product/utils'
import { permission, historyOption, viewPricesOption } from './const'
import priceApi from '@/api/product/price'

export default {
  name: 'priceMange',
  components: {
    BaseProductPage,
    setPriceTable,
    helpDialog,
    importPriceBtn
  },
  data() {
    return {
      permission,
      isSearch: this.checkPermission(['externaladmin:product:maintain:priceManage:search']),
      categoriesList: [],
      generate: false,
      loading: true,
      url: '/externaladmin/prototypeService/productPrototype/list',
      method: 'post',
      showEditPriceDialog: false,
      gearsNum: 0,
      categories: '',
      product: {},
      priceFormData: {},
      geaData: [],
      priceData: [],
      ruleForm: {
        size: null,
        gear: null,
        init_price: null
      },
      rules: {
        size: [
          { required: true, message: '尺码公差不能为空', trigger: 'blur' },
          { validator: validNumber, trigger: 'blur' }
          // { validator: validateSize, trigger: 'blur' }
        ],
        gear: [
          { required: true, message: '档位公差不能为空', trigger: 'blur' },
          { validator: validNumber, trigger: 'blur' }
          // { validator: validateSize, trigger: 'blur' }
        ],
        init_price: [
          { required: true, message: '初始价格不能为空', trigger: 'blur' },
          { validator: isMoreZero, trigger: 'blur' },
          { validator: numMaxConstruct(99999999.99) }
        ]
      }
    }
  },
  activated() {
    this.initAllData()
  },
  watch: {
    showEditPriceDialog(newVal) {
      if (!newVal) {
        this.clearFormData()
      }
    }
  },
  methods: {
    getValueFromObj,
    isSetPrice({ sizeList }) {
      if (!sizeList || !sizeList[0].sizeLevelCategoryPriceList) return false
      return true
    },
    clearFormData() {
      this.categories = ''
      this.product = {}
      this.priceFormData = {}
      this.geaData = []
      this.priceData = []
      this.ruleForm = {
        size: null,
        gear: null,
        init_price: null
      }
    },

    initAllData() {
      this.showEditPriceDialog = false
      this.sup_this
    },
    initProduct() {
      let { sizeList = [] } = this.product
      sizeList = sizeList ? sizeList : []
      const tempArr = []
      sizeList.map(({ sizeName, id, lowerLimitPrice }) => {
        tempArr.push({
          size: sizeName,
          size_id: id,
          lowerLimitPrice
        })
      })
      this.priceData = tempArr
    },

    updateProduct(data, priceList = []) {
      this.priceData = this.priceData.map((item) => {
        const { size, size_id, lowerLimitPrice } = item
        item = {
          size,
          size_id,
          lowerLimitPrice
        }
        this.$set(item, 'ids', [])
        item.priceIds = {}
        let sizePriceList = priceList.filter((price) => price.sizeId === size_id)
        data.map(({ id }, index) => {
          let priceKey = `price${index + 1}`
          let price = sizePriceList.find((price) => price.levelItemId === id) || {
            price: ''
          }
          item.priceIds[priceKey] = price.id
          this.$set(item, priceKey, price.price)
          item.ids.push(id)
        })
        return item
      })
      // console.log(this.priceData)
      const { setPriceTable } = this.$refs
      if (setPriceTable) {
        setPriceTable.priceFormData.list = this.priceData
      }
    },

    async handleSettingPrice(data) {
      this.generate = false
      this.getGears()
      this.product = data
      this.initProduct()
      this.showEditPriceDialog = true
      // 获取档位分类数据
      const categoriesList = this.categoriesList
      if (categoriesList.length) {
        let categories = (this.categories = data.productPrototypePlatform.levelCategoryId || categoriesList[0].id)
        await this.getCategorys(categories, data.id)
      }
    },
    async getCategorys(categories, id) {
      id = id || this.product.id
      this.loading = true
      const categoryId = categories
      this.categories = categories
      try {
        const [{ detail }, { detail: priceData }] = await Promise.all([
          getCurrentGears({ categoryId }),
          id ? getPrice({ id }) : Promise.resolve({}),
          // 获取会员列表，计算出最低折扣 lowestDiscount
          this.$store.dispatch('GetDic', 'memberLevelList')
        ])
        this.geaData = detail
        this.updateProduct(detail, priceData)
        id && (this.curPriceList = priceData)
      } catch (err) {}
      this.loading = false
    },
    takeRefs(data, form) {
      this.this_ref = data
      this.priceFormData = form
    },
    async handleConfirmed() {
      //  获取表单里面的数据
      let valid = await awaitFormResolve(this.this_ref.validate())
      if (!valid) return
      valid = await validateProtoUnShelf({ id: this.product.id })
      if (valid) {
        let { list, ...form } = this.priceFormData
        let formKeys = []
        // console.log(list)
        list.map((lItem) => {
          const { ids, size, size_id, priceIds, ...rest } = lItem
          // console.log('rest', rest)
          formKeys = formKeys.concat(Object.keys(rest))
        })
        // console.log(formKeys)
        this.priceData.forEach((row, index) => {
          formKeys.map((key) => {
            this.$set(row, key, form[key + index])
            // row[key] = form[key + index]
          })
        })

        const priceColData = this.priceColData
        const priceData = this.priceData
        const postData = []
        priceData.map((item, index) => {
          const { ids, size, size_id, priceIds, lowerLimitPrice, ...rest } = item
          const len = Object.keys(rest).length
          let num = -1
          for (let key in rest) {
            num++
            if (!postData[len * index + num]) {
              postData[len * index + num] = {}
            }
            postData[len * index + num].id = priceIds[key]
            postData[len * index + num].price = parseFloat(rest[key])
            postData[len * index + num].levelItemId = ids[num]
            postData[len * index + num].sizeId = size_id
            postData[len * index + num].lowerLimitPrice = lowerLimitPrice
          }
        })
        // console.log('postData', postData, priceData, form)

        const res = await awaitResolve(
          updatePrice({
            list: postData,
            prototypeId: this.product.id,
            levelCategoryId: this.categories
          })
        )
        if (res) {
          this.$message({
            type: 'success',
            message: '价格设置成功'
          })
          //调用保存价格数据接口,成功回调中提示保存成功并关闭对话框
          this.$refs.BaseProductPage.refreshPage()
          this.showEditPriceDialog = false
        }
      }
    },
    handleCanceled() {
      this.showEditPriceDialog = false
    },
    handleButtonClicked() {
      this.generate = false
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let { priceFormData } = this
          this.generate = false
          this.priceData.map((item, index) => {
            item.lowerLimitPrice = priceFormData[`lowerLimitPrice${index}`]
            if (index === 0) {
              item.price1 = this.ruleForm.init_price
            }
            if (index !== 0) {
              item.price1 = Number(this.priceData[index - 1].price1) + Number(this.ruleForm.size)
            }
          })
          // console.log('此时的price1', this.priceData)
          this.priceData.map((item) => {
            const { ids, size, size_id, priceIds, lowerLimitPrice, ...rest } = item
            const priceArr = Object.keys(rest).sort((a, b) => {
              return +a.replace('price', '') - +b.replace('price', '')
            })
            priceArr.map((key, index) => {
              let data = Number(item['price1']) - index * Number(this.ruleForm.gear)
              data = data.toFixed(2)
              this.$set(item, key, data)
            })
          })
        } else {
          this.generate = true
          return
          this.$message({
            type: 'error',
            message: '为确保表格每一项都为正数，请输入合适的公差'
          })
        }
      })
    },
    handleClosed() {
      this.ruleForm.size = null
      this.ruleForm.gear = null
      this.ruleForm.init_price = null
    },
    async getGears() {
      await getGearsCategoryList({
        name: null,
        enabled: 1,
        page: {
          pageIndex: 1,
          pageSize: 0
        }
      }).then((res) => {
        this.categoriesList = res.detail
      })
    },

    spanMethod({ row, columnIndex }) {
      if (row.$expandLevel === 2) {
        if (columnIndex === 0) {
          return [1, 5]
        } else {
          return [0, 0]
        }
      }
      return [1, 1]
    },
    rowClassName({ row: { $expandLevel } }) {
      if ($expandLevel === 2) return 'bg-transparent'
    },
    viewPrices(row) {
      let { column, data } = handlePriceData(row.levelCategoryPriceDetail)
      viewPricesOption.column = column
      this.$dialogTable({
        title: '查看价格',
        data,
        option: viewPricesOption
      })
    },

    async exportPriceExcel() {
      const [err, selectedData] = await this.$refs.BaseProductPage.getAllSelectedDataData()
      if (err) return
      if (!selectedData?.length) {
        return this.$message.warning('请至少选择一条数据')
      }
      const errMsg = []
      selectedData.forEach((item) => {
        if (!this.isSetPrice(item)) {
          errMsg.push(item.name)
        }
      })
      if (errMsg.length) {
        return this.$message.warning(`未设置价格产品：${errMsg.slice(0, 3).join('、')}...`)
      }

      return exportWrap(
        priceApi.exportPrice({
          idList: selectedData.map((item) => item.id)
        }),
        'zdcustom产品价格表'
      )
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    cols({ isMain }) {
      if (!isMain) {
        return cols.filter((item) => item.value !== 'manager')
      }
      return cols
    },
    formField({ isMain }) {
      if (!isMain) {
        return formField.filter((item) => item.value !== 'manager')
      }
      return formField
    },
    keysArray() {
      if (this.priceData[0]) return Object.keys(this.priceData[0])
    },
    priceColData() {
      const priceColData = [
        {
          prop: 'size',
          label: '尺寸'
        },
        {
          prop: 'lowerLimitPrice',
          label: '最低售价',
          minWidth: '100px'
        }
      ]
      this.geaData.map((item, index) => {
        const { maxCount, minCount, id } = item
        let label =
          index !== this.geaData.length - 1 ? `${minCount}-${maxCount}件价格（元）` : `${minCount}+件价格（元）`
        priceColData.push({
          prop: `price${index + 1}`,
          label,
          id,
          minWidth: '175px'
        })
      })
      return priceColData
    },
    figure() {
      return (row) => {
        try {
          return row.styleList[0].styleDisplayImageList[0].displayImagePath || require('@/assets/images/default.png')
        } catch (err) {
          return require('@/assets/images/default.png')
        }
      }
    },
    historyOption() {
      this.$store.dispatch('HandleOption', historyOption)
      historyOption.menuFn = this.viewPrices
      return historyOption
    }
  },
  created() {
    this.getGears()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .dialog-title {
    font-size: 14px;
    color: #1a1a1a;
    height: 52px;
    display: flex;
    align-items: center;
  }
}

::v-deep {
  .el-form--inline .el-form-item__label {
    color: #595961;
    font-weight: normal;
  }
}

// ::v-deep {
//   .el-input--small .el-input__inner {
//     width: 240px;
//     height: 36px;
//     margin-left: 15px;
//   }
// }

.flex-warpper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base-dialog-wrapper {
  color: #595961;

  .price-wrapper {
    .guide {
      position: absolute;
      z-index: 999;
      transform: translateX(-100%);
      left: -3px;
    }
    .init-price {
      height: 36px;
      line-height: 36px;
      .generate-button {
        margin-left: 28px;
      }
      .el-button {
        height: 36px;
      }
    }
    .selectLabel {
      font-weight: normal;
      width: 155px;
      padding-right: 9px;
      text-align: right;
      display: inline-block;
    }
    .el-select {
      margin-bottom: 20px;
    }
  }
  .setting-price-wrapper {
    margin-top: 10px;
    .gears-wrapper {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      min-height: 80px;
      border: 1px solid $border-color;
    }
  }
  .product-wrapper {
    margin-bottom: 24px;
    align-items: center;
    line-height: 23px;
    .el-image {
      margin-right: 10px;
    }
  }
  .flex {
    display: flex;
  }
}
.setPriceStyle {
  margin-right: 10px;
}
</style>
